import './InDev.css';
import '../../App.css';

function InDev() {

    return (
        <div className="indev">
            This page is in development.
        </div>
    );
}

export default InDev;
